import styled from 'styled-components'
import {GlobalFonts, GlobalColors} from "../../styles/GlobalStyles";
import ImgBackHome from '../../images/home/back-home.jpg';

export const ContBackHome = styled.div`
  width: 100%;
  
  background-image: url(${ImgBackHome});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 400px;    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 500px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 600px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 700px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: 800px;
  }
  @media(min-width: 1920px){
    height: 900px;
  }
`
export const ContLema = styled.div`
  position: absolute;
  
  @media(min-width: 300px) and (max-width: 767px){
    top: 4%;
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 3%;
    width: 420px;
    right: 5%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    top: 7%;
    width: 420px;
    right: 5%;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    top: 7%;
    width: 420px;
    right: 5%;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    top: 7%;
    width: 420px;
    right: 5%;
  }
  @media(min-width: 1408px){
    top: 7%;
    width: 420px;
    right: 10%;
  }
`
export const ContBlurLema = styled.div`
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 5px;
  opacity: .8;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 200px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 200px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 200px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 200px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: 200px;
  }
  @media(min-width: 1920px){
    height: 200px;
  }
`
export const Lema = styled.div`
  position: absolute;
  width: 90%;
  top: 9%;
  right: 4%;
  text-align: right;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 40px;
  line-height: 50px;
  color: ${GlobalColors.colorPrimary};
  
  & > div:nth-child(1){
    margin-bottom: 25px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 35px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 37px;
    line-height: 45px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 37px;
    line-height: 45px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    font-size: 37px;
    line-height: 45px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    font-size: 37px;
    line-height: 45px;
  }
  @media(min-width: 1408px){
    font-size: 37px;
    line-height: 45px;
  }  
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
