import React from 'react';
import {Column, Columns} from 'bloomer';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {ContainerNosotros, ContNosotros, ContNosotrosImage, ContNosotrosTexto} from './styles';
//import imgNosotros from '../../images/home/nosotros.jpg';
//import imgNosotros2x from '../../images/home/nosotros@2x.jpg';
import {ContTitle} from '../../styles/GlobalStyles';

export const NosotrosComp = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/nosotros.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  return (
    <ContNosotros id={'nosotros'}>
      <ContainerNosotros>

        <ContTitle>Nosotros</ContTitle>

        <Columns className="columns-responsive full-height columns-reorder-mobile">

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContNosotrosTexto>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ornare quam odio, ac commodo quam eleifend at. Donec porta lectus gravida, suscipit arcu dictum, tristique sapien. Phasellus nunc nisl, consectetur a mi eu, tincidunt dapibus augue. Aliquam erat volutpat. In volutpat velit in lacus ultricies pellentesque. Mauris molestie, sem in fermentum faucibus, lorem dolor pretium tellus, vel semper ipsum ligula quis turpis. Phasellus varius, ligula sed blandit porta, eros Leo vulputate ex, sit amet tempus diam ipsum id massa. Proin accumsan dolor et vestibulum faucibus.
            </ContNosotrosTexto>
          </Column>

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContNosotrosImage>
              {/*<img src={imgNosotros} alt={'Nosotros'}/>*/}
              <Img fluid={data.file.childImageSharp.fluid} alt={'Nosotros'} />
            </ContNosotrosImage>
          </Column>

        </Columns>
      </ContainerNosotros>
    </ContNosotros>
  )
}
