import React from 'react';
import {GlobalStyle} from './styles';
import {HomeBackComp} from '../HomeBack';
import {NosotrosComp} from '../Nosotros';
import {DesarrollosComp} from '../Desarrollos';

const HomeComp = (props) => {

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>

      <HomeBackComp/>
      <NosotrosComp/>
      <DesarrollosComp/>

    </div>
  )
}

export default HomeComp
