/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { Container } from 'bloomer';
import { GlobalStyleLayout } from './styles';
import { GlobalStyle } from '../../styles/GlobalStyles'
import { GlobalFonts } from '../../styles/fonts';
import '../../styles/bulma/css/bulma.min.css';
import Header from "../Header/index"
import FooterComp from '../Footer';

const Layout = ({ children }) => {

  //console.log('children => ', children);
  //console.log('title => ', title);

  return (
    <div className={`layout`}>
      <GlobalStyle />
      <GlobalFonts />
      <GlobalStyleLayout/>

      <Header/>

      <Container isFluid className="wrapper-pages">
        {children}
      </Container>

      <FooterComp/>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
