import styled from 'styled-components'
import {} from "../../styles/GlobalStyles";
import {GlobalFonts} from '../../styles/GlobalStyles';
import {GlobalColors} from '../../styles/GlobalStyles';
import {GlobalBackgroundColors} from '../../styles/GlobalStyles';

export const ContNosotros = styled.div`
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GlobalBackgroundColors.bgSecondary};
`
export const ContainerNosotros = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 80%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 60%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 60%;
  }
  @media(min-width: 1920px){
    width: 60%;
  }
`
export const ContNosotrosTexto = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14x;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 16px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 20px;
  }
  @media(min-width: 1920px){
    font-size: 20px;
  }
`

export const ContNosotrosImage = styled.div`
  
`
