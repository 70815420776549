import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import {ContDesarrollos} from './styles';
import {ContTitle} from '../../styles/GlobalStyles';
import {CardGuzbarraf} from '../UI/Card';
import PuntaEscondida from '../../images/desarrollos/punta-escondida/card-portada.jpg';
import DelValle from '../../images/desarrollos/del-valle/card-portada.jpg';
import PuntaArena68 from '../../images/desarrollos/punta-arena-68/card-portada.jpg';


export const DesarrollosComp = () => {

  return (
    <ContDesarrollos id={'desarrollos'}>
      <Container>
        <ContTitle>Desarrollos</ContTitle>

        <Columns className="columns-responsive full-height columns-reorder-mobile">
          <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
            <CardGuzbarraf
              title={'Punta Escondida'}
              subTitle={'Se ubica a 10 min. de Puerto Escondido en los Alejos Bajos de Chila, San Pedro Mixtepec, Juquila, Oaxaca.'}
              image={PuntaEscondida}
              link={'puntaescondida'}
            />
          </Column>

          <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
            <CardGuzbarraf
              title={'Del Valle'}
              subTitle={'Se ubica a 10 min. de Puerto Escondido en los Alejos Bajos de Chila, San Pedro Mixtepec, Juquila, Oaxaca.'}
              image={DelValle}
              link={'delvalle'}
            />
          </Column>

          <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
            <CardGuzbarraf
              title={'Punta Arena 68'}
              subTitle={'Se ubica a 15 min. de Puerto Escondido en Rio Valdeflores, Santa María Colotepec, Pochutla, Oaxaca.'}
              image={PuntaArena68}
              link={'puntaarena68'}
            />
          </Column>


        </Columns>

      </Container>
    </ContDesarrollos>
  )
}
