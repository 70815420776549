import styled from 'styled-components';
import {Content, Subtitle, Title} from 'bloomer';
import {GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';

export const TitleCard = styled(Title)`
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorLink}
`
export const SubTitleCard = styled(Subtitle)`
  margin-top: 20px;
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary}
`
export const ContentCard = styled(Content)`
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary}
`
