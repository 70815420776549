import * as React from "react"
import Layout from '../components/Layout'
import Seo from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import HomeComp from "../components/Home";

const IndexPage = (props) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <Layout>
      <Seo
        title={title}
        description="Seguridad y confianza en tu patrimonio"
        keywords='patrimonio, terrenos, bienes raices, inmobiliaria'
        lang={`es`}
        imageF='inmoax-home-f.jpg'
        imageT='inmoax-home-t.jpg'
        url={`${siteUrl}/`}
      />

      <HomeComp {...props} />

    </Layout>
  )
}

export default IndexPage
