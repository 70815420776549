import React from 'react';
import { Link } from "gatsby"
import {
  Card,
  CardContent,
  CardImage,
  Image,
  Media,
  MediaContent,
} from 'bloomer';
import {SubTitleCard, TitleCard} from './styles';

export const CardGuzbarraf = ({title, subTitle, image, link}) => {

  return (
    <Card>
      <CardImage>
        <Link to={link}>
          <Image isRatio='4:3' src={image}/>
        </Link>
      </CardImage>
      <CardContent>
        <Media>
          <MediaContent>
            <Link to={link}>
              <TitleCard isSize={4}>{title}</TitleCard>
            </Link>
            <SubTitleCard isSize={6}>{subTitle}</SubTitleCard>
          </MediaContent>
        </Media>
        {/*<ContentCard>
          People Keep Asking If I’m Back, And I Haven’t Really Had An Answer, But Now, Yeah, I’m Thinking I’m Back.
        </ContentCard>*/}
      </CardContent>
    </Card>
  );
};
