import React from 'react';
import {ContBackHome, ContBlurLema, ContButton, ContLema, Lema} from './styles';
import ButtonGuzbarraf from '../UI/Button';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const HomeBackComp = () => {

  const fnGoContacto = () => {
    scrollTo(`#contacto`);
    setTimeout(function(){
      document.getElementById('txtNombre').focus();
    }, 1200);
  }

  return (
    <ContBackHome id={'inicio'}>
      <ContLema>
        <ContBlurLema>&nbsp;</ContBlurLema>
        <Lema>
          <div>Seguridad y confianza en tu patrimonio</div>
          <ContButton>
            <ButtonGuzbarraf
              text={'Contactar'}
              className={'btnPrimary'}
              onClick={() => fnGoContacto()}
            />
          </ContButton>
        </Lema>
      </ContLema>
    </ContBackHome>
  )
}
